import * as React from "react";
import styled from "styled-components";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "../icons";
import Container from "./common/Container";
import { SocialLink } from "./Contact";
import { Link } from "./Header";
import LogoFull from "./LogoFull";

const FooterStyled = styled.footer`
  padding: 36px 16px;
  background-color: var(--text-darker);

  @media only screen and (max-width: 1240px) {
    padding: 36px 24px;
  }

  .links {
    margin-left: -16px;
  }
  @media only screen and (max-width: 860px) {
    .links {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  .first-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const FooterLink = styled(Link)`
  font-size: 16px;
  padding: 0px 16px;
  @media only screen and (max-width: 424px) {
    font-size: 14px;
  }
`;
const LogoContainer = styled.div`
  @media only screen and (max-width: 860px) {
    display: none;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  .logo {
    display: none;
  }
  @media only screen and (max-width: 860px) {
    .logo {
      display: block;
    }
    display: flex;
    flex-direction: column;
    align-items: center;

    .copy {
      margin-top: 36px;
      margin-bottom: 16px;
    }
  }
`;
const Footer = () => {
  return (
    <FooterStyled id="footer">
      <Container>
        <div className="first-row">
          <div className="links">
            <FooterLink href="#soping" className="link">
              Za kupce
            </FooterLink>
            <FooterLink href="#za-prodavce" className="link">
              Za prodavce
            </FooterLink>
            <FooterLink href="#kontakt" className="link">
              Kontakt
            </FooterLink>
            <FooterLink href="/privacy" className="link">
              Politika privatnosti
            </FooterLink>
            <FooterLink href="/terms" className="link">
              Uslovi korišćenja
            </FooterLink>
          </div>
          <LogoContainer>
            <LogoFull />
          </LogoContainer>
        </div>
        <div className="gray-divider" />
        <Socials>
          <div className="icons">
            <SocialLink
              href="https://www.facebook.com/konfidi1"
              target="_blank"
            >
              <FacebookIcon color="#fff" />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/konfidi.me/"
              target="_blank"
            >
              <InstagramIcon color="#fff" />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/konfidi1"
              target="_blank"
            >
              <LinkedInIcon color="#fff" />
            </SocialLink>
          </div>
          <div className="copy text-light">
            Copyright ©2021 Konfidi. All rights reserved
          </div>
          <div className="logo">
            <LogoFull />
          </div>
        </Socials>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
