import * as React from "react";

const LogoFull = (props: any) => (
  <svg
    width={104}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.583 6.885h-5.099l-7.491 4.627V3.2h-2.986v13.97h2.986v-2.375l3.51-2.113 4.924 4.488h4.156l-6.67-6.077 6.67-4.208Z"
      fill="#fff"
    />
    <path
      d="M43.355 17.345c4.243 0 7.124-1.537 7.124-5.309 0-3.789-2.881-5.326-7.124-5.326-4.261 0-7.125 1.537-7.125 5.326 0 3.772 2.864 5.309 7.125 5.309Zm0-2.497c-2.777 0-4.139-.681-4.139-2.812 0-2.148 1.362-2.829 4.139-2.829 2.759 0 4.12.681 4.12 2.83 0 2.13-1.361 2.81-4.12 2.81ZM61.29 6.71c-2.497 0-4.523.821-5.99 1.974V6.885h-2.986V17.17H55.3v-5.762c.926-1.083 3.091-2.113 5.1-2.113 2.461 0 3.195 1.135 3.195 3.318v4.557h3.003l.035-5.693c-.035-3.789-2.602-4.767-5.343-4.767ZM75.874 5.226c.769 0 1.467.035 2.148.087V3.201c-1.52-.175-2.06-.175-3.09-.175-3.004 0-4.261.995-4.261 3.09v.769h-2.707v2.497h2.707v7.788h3.003V9.382h4.348V6.885h-4.348c.052-1.292.489-1.659 2.2-1.659ZM79.853 3.2v2.602h2.986V3.201h-2.986Zm0 3.685V17.17h2.986V6.885h-2.986ZM95.937 3.2v5.291c-1.117-1.17-2.916-1.78-5.133-1.78-3.3 0-6.13 1.396-6.13 5.325 0 3.93 2.83 5.309 6.13 5.309 2.217 0 4.016-.611 5.133-1.781v1.606h2.986V3.2h-2.986ZM91.8 14.848c-2.672 0-4.121-.786-4.121-2.812 0-2.043 1.45-2.829 4.12-2.829 2.672 0 4.14.786 4.14 2.83 0 2.025-1.468 2.81-4.14 2.81ZM101.014 3.2v2.602H104V3.201h-2.986Zm0 3.685V17.17H104V6.885h-2.986Z"
      fill="#fff"
    />
    <path
      d="M0 7.712c0-.675.474-1.496 1.059-1.833L10.99.145c.585-.338 1.059-.064 1.059.61v7.722c0 .675-.474 1.496-1.059 1.834L1.06 16.045C.474 16.382 0 16.109 0 15.433V7.713Z"
      fill="#fff"
      fillOpacity={0.65}
    />
    <path d="m0 9.633 12.049-6.956v1.027L0 10.66V9.633Z" fill="#8216D5" />
    <path
      d="M0 7.712c0-.675.474-.949 1.059-.611l9.931 5.734c.585.337 1.059 1.158 1.059 1.833v7.722c0 .675-.474.949-1.059.611L1.06 17.267C.474 16.93 0 16.109 0 15.434V7.712Z"
      fill="#fff"
    />
    <path d="M0 10.443 12.049 17.4v1.026L0 11.47v-1.027Z" fill="#8216D5" />
  </svg>
);

export default LogoFull;
